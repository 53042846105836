.title {
    position: absolute;
    top: 0;
    font-style: italic;
    font-weight: 600;
    font-size: 14px;
}

.song {
    font-size: 26px;
    /* max-height: 30px; */
    white-space: nowrap;
    /* padding-left: 15px; */
    animation: marquee 20s linear infinite;
    width: auto;
}

@keyframes marquee {
    0% { transform: translateX(-100%)}
    100% { transform: translateX(235px)}
}