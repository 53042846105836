.App {
    width: 312px;
    height: 64px;
    overflow: hidden;
    color: white;
    display: flex;
    align-items: center;
    position: relative;
    opacity: 0; /* Start with 0 opacity */
    visibility: hidden; /* Start hidden */
    transition: opacity 0.5s ease-in-out; /* Transition opacity */
}

.App.visible {
    opacity: 1; /* Fully visible */
    visibility: visible; /* Visible, but controlled in JS for smoother effect */
}

.logo {
    height: 50px !important;
    width: 50px !important;
    overflow: hidden;
    display: flex;
    flex: 1 1;
    padding: 0 10px;
    z-index: 1;
}

.video-container {
    width: 64px;           /* Container width */
    height: 100%;          /* Full height container */
    overflow: hidden;      /* Ensure the video stays inside the container */
    position: relative;    /* Set positioning context */
    border-radius: 10px;
}

video {
    width: 100%;
    /* height: 100%; */
    position: relative;
    /* left: 50%; */
    transform: translateY(-25%);
}


.logo > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.nowplaying {
    flex: 5;
    font-family: Quicksand, sans-serif;
    overflow-x: hidden;
    position: relative;
}

.poweredby {
    position: absolute;
    font-size: 8px;
    font-style: italic;
    font-weight: 500;
    bottom: 6px;
    text-align:center;
    width: 100%;
}