.Generator {
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    background-color: #f2f4f7;
    flex-direction: column;
    color: black;
}

.Generator > nav {
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    margin-bottom: 10vh;
    text-align: center;
}

.preview {
    background-image: url("./background.png");
    width: 400px;
    height: 300px;
    color: white;
    padding-left: 44px;
    padding-top: 30px;
    background-repeat: space;
    display: block !important;
    color: rgba(255,255,255,100) !important;
}

.widthheight {
    width: 400px;
    text-align: center;
    color: #737f8d;
    font-size: 1.1rem;
}

input, select {
    border: 2px white;
    border-radius: 3px;
    font-size: 16px;
    line-height: 20px;
    color: rgba(79,84,92,.6);
    font-weight: 700;
    padding: 14px 16px;
    box-shadow: 0 3px 3px 0 rgba(0,0,0,.05);
}

input {
    width: 300px;
}


textarea, select {
    border: 2px white;
    border-radius: 3px;
    font-size: 16px;
    line-height: 20px;
    color: rgba(79,84,92,.6);
    font-weight: 700;
    padding: 14px 16px;
    box-shadow: 0 3px 3px 0 rgba(0,0,0,.05);
}

textarea {
    width: 300px;
}



select, option {
    appearance: none;
    width: 150px;
}

.selection {
    display: flex;
    justify-content: right;
    position: relative;
}

.selection::after {
    position: absolute;
    content: "";
    top: 20px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: rgba(79,84,92,.6) transparent transparent transparent;
}

.setting > input {
    float: right;
}

.setting > input#url {
    width: 300px;
}

.setting.select {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.setting.select > .text {
    display: flex;
    justify-content: left;
}
.setting.select > .selection {
    padding: 0;
}

.setting > div {
    display: inline-block;
    height: 44px;
    padding-top: 10px;
    font-size: 1.3rem;
}

.link-generator {
    width: 368px;
}

.widthheight {
    padding-top: 15px;
}

.react-switch-handle {
    margin-top: 10px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}